import i18n from "i18next";
import Backend from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";
import { contentVersion, mainLanguage } from "./config";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: mainLanguage,
    ns: ["main"],
    defaultNS: "main",
    fallbackLng: mainLanguage,
    preload: [mainLanguage],
    react: {
      transKeepBasicHtmlNodesFor: ["br", "b", "u", "span", "strong"],
    },
    backend: {
      loadPath: `/static/i18n/{{lng}}/{{ns}}.json?v=${contentVersion}`,
    },
  });

export default i18n;
