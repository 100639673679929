import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { availableLanguages, contactUsLink, helpLink } from "../../config";
import { useClickOutside } from "../../shared/hooks/click";
import { Link } from "react-router-dom";

import logo from "../../assets/img/logo.svg";

import "./Header.scss";

export default function Header() {
  const [isMenuOpen, setMenu] = useState(false),
    { t, i18n } = useTranslation();

  const toggleMenu = () => setMenu(!isMenuOpen);

  const [mobileMenuRef] = useClickOutside(() => setMenu(false));

  return (
    <header id="Header" className={classNames({ "nav-open": isMenuOpen })}>
      <div className="HeaderContainer">
        <div id="Logo">
          <Link to="/" className="logo">
            <img
              height={35}
              width={177}
              src={logo}
              alt="Blue Transfer"
              title="Blue Transfer"
            />
          </Link>
        </div>
        <nav>
          <ul ref={(el) => (mobileMenuRef.current["ul"] = el)}>
            <li className="divider" />
            <li>
              <Link to="/comment-ça-marche" onClick={toggleMenu}>
                {t("menu.howItWorks")}
              </Link>
            </li>
            <li className="help">
              <a href={helpLink(i18n.language)} target="_blank">
                {t("menu.help")}
              </a>
            </li>
            <li className="contactUs">
              <a href={contactUsLink(i18n.language)} target="_blank">
                {t("menu.contactUs")}
              </a>
            </li>
            <li id="LangSelector" className="secondary flags">
              <button
                className={"currentLang " + i18n.language}
                aria-label={i18n.language}
              />
              {availableLanguages.length > 0 && (
                <ol>
                  {availableLanguages.map((lang, key) => {
                    return (
                      <li key={key}>
                        <button
                          title={lang}
                          className={"flag " + lang}
                          onClick={() => i18n.changeLanguage(lang)}
                          aria-label={lang}
                        ></button>
                      </li>
                    );
                  })}
                </ol>
              )}
            </li>
          </ul>
        </nav>
        <div
          id="hamburger"
          tabIndex="0"
          ref={(el) => (mobileMenuRef.current["hamburger"] = el)}
          onClick={toggleMenu}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    </header>
  );
}
