import React, { useReducer } from "react";

export const BOOKING_CONTEXT_KEY = "bookingContext";
export const BOOKING_CONTEXT_STORAGE_VERSION = 1;

export const bookingSteps = {
  home: "1-HOME",
  info: "2-INFO",
  payment: "3-PAYMENT",
  validation: "4-VALIDATION",
  confirmed: "5-CONFIRMED",
};

export const bookingActions = {
  updateStep: "updateStep",
  updateLoading: "updateLoading",

  updateSites: "updateSites",
  updateSite: "updateSite",
  updateStartDate: "updateStartDate",
  updateStartTime: "updateStartTime",
  updateEndDate: "updateEndDate",
  updateEndTime: "updateEndTime",
  updatePassengers: "updatePassengers",
  updatePriceAndDays: "updatePriceAndDays",

  updateCancellationOption: "updateCancellationOption",

  updateOrderId: "updateOrderId",
  updateOrder: "updateOrder",
  updateOrderStripe: "updateOrderStripe",
  updatePaymentMessage: "updatePaymentMessage",
  updateCardHolder: "updateCardHolder",

  resetCart: "resetCart",

  updateContextObject: "updateContextObject",

  validateRequired: "validateRequired",
};

const initBooking = {
  step: bookingSteps.home,
  isLoading: false,

  // HOME FIELDS
  sites: [],
  site: null,
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  passengers: null,
  totalDays: 0,
  totalPrice: 0,
  dailyPrice: 0,

  // INFO FIELDS
  departureMeetingPointId: null,
  arrivalMeetingPointId: null,
  arrivalFlightNumber: null,
  arrivalFlightUnknown: false,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  zipCode: "",
  cancellationOption: false,

  cardHolder: "",
  paymentMessage: "",

  errors: null,
};

function getMeetingPointIdValue(state, currentSiteId) {
  const meetingPoints = state.sites.find((site) => site.id === currentSiteId)?.meetingPoints;

  return meetingPoints?.length === 1 ? meetingPoints[0]?.id : null;
}

const bookingReducer = (state, action) => {
  switch (action.type) {
    case bookingActions.updateStep:
      return {
        ...state,
        step: action.payload,
        errors: null,
      };
    case bookingActions.updateLoading:
      return {
        ...state,
        isLoading: action.payload,
      };

    case bookingActions.updateSites:
      return {
        ...state,
        sites: action.payload,
      };
    case bookingActions.updateSite:
      return {
        ...state,
        site: state.sites.find((site) => site.id === action.payload),
        departureMeetingPointId: getMeetingPointIdValue(state, action.payload),
        arrivalMeetingPointId: getMeetingPointIdValue(state, action.payload),
        siteIndex: action.payload,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        passengers: null,
        totalDays: 0,
        totalPrice: 0,
      };
    case bookingActions.updateStartDate:
      return {
        ...state,
        startDate: action.payload,
        startTime: null,
      };
    case bookingActions.updateStartTime:
      return {
        ...state,
        startTime: action.payload,
      };
    case bookingActions.updateEndDate:
      return {
        ...state,
        endDate: action.payload,
        endTime: null,
      };
    case bookingActions.updateEndTime:
      return {
        ...state,
        endTime: action.payload,
      };
    case bookingActions.updatePassengers:
      return {
        ...state,
        passengers: action.payload,
      };
    case bookingActions.updatePriceAndDays:
      return {
        ...state,
        totalDays: action.payload.totalDays,
        totalPrice: action.payload.totalPrice,
        dailyPrice: action.payload.dailyPrice,
      };
    case bookingActions.updateCancellationOption:
      const price = action.payload.status
        ? state.totalPrice + action.payload.price
        : state.totalPrice - action.payload.price;
      return {
        ...state,
        cancellationOption: action.payload.status,
        totalPrice: price,
      };

    case bookingActions.updateOrderId:
      return {
        ...state,
        orderId: action.payload,
      };
    case bookingActions.updateOrder:
      return {
        ...state,
        order: action.payload,
      };
    case bookingActions.updateOrderStripe:
      return {
        ...state,
        order: { ...state.order, stripe: action.payload },
      };
    case bookingActions.updatePaymentMessage:
      return {
        ...state,
        paymentMessage: action.payload,
      };

    case bookingActions.updateCardHolder:
      return {
        ...state,
        cardHolder: action.payload,
      };

    case bookingActions.resetCart:
      return {
        ...initBooking,
      };

    case bookingActions.updateContextObject:
      return {
        ...state,
        ...action.payload,
      };

    case bookingActions.validateRequired:
      const hasError = !action.payload.value;
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: hasError,
        },
      };

    default:
      return state;
  }
};

export const BookingContext = React.createContext();

export const BookingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(bookingReducer, initBooking);

  return <BookingContext.Provider value={{ state, dispatch }}>{children}</BookingContext.Provider>;
};
