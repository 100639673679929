import LogoAnimated from "../../../assets/img/logo-animated.svg";
import React from "react";

export default function Loader({ containerStyles }) {
  return (
    <div style={{ width: "100%", height: "100%", ...containerStyles }}>
      <img
        alt="Loader"
        src={LogoAnimated}
        style={{
          marginTop: "-50px",
          display: "block",
          top: "50%",
          position: "absolute",
          left: "50%",
        }}
      />
    </div>
  );
}
