import { useEffect, useState } from "react";
import { addGtmEvent, addPageView } from "./service";

export function useGtmPageView() {
  const [pageView, setPageView] = useState(false);

  useEffect(() => {
    if (!pageView) {
      addPageView();
      setPageView(true);
    }
  }, [pageView]);
}

export function useGtmUniqueEvent(eventName) {
  const [pageEvent, setPageEvent] = useState(false);

  useEffect(() => {
    if (!pageEvent) {
      addGtmEvent(eventName);
      setPageEvent(true);
    }
  }, [pageEvent, eventName]);
}
