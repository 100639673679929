import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW,
};

function gtag() {
  window.dataLayer.push(arguments);
}

export function initGtm() {
  window.dataLayer = window.dataLayer || [];
  initGtmConsent();
  TagManager.initialize(tagManagerArgs);
}

export function initGtmConsent() {
  gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "denied",
  });
}

export function updateGtmConsent(consent) {
  gtag("consent", "update", {
    ad_storage: consent.details.adStorage,
    ad_user_data: consent.details.adUserData,
    ad_personalization: consent.details.adPersonalization,
    analytics_storage: consent.details.analyticsStorage,
    functionality_storage: consent.details.functionalityStorage,
    personalization_storage: consent.details.personalizationStorage,
    security_storage: consent.details.securityStorage,
  });
}

export function addPageView() {
  window.dataLayer.push({
    event: "pageView",
  });
}

export function addGtmEvent(eventName) {
  window.dataLayer.push({
    event: eventName,
  });
}

export function addEcommerceDataLayer(order) {
  // Clear the previous ecommerce object.
  window.dataLayer.push({ ecommerce: null });

  // UA
  window.dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          id: order.id,
          revenue: order.totalPriceInclTaxes,
          tax: +order.totalPriceInclTaxes - +order.totalPriceExclTaxes,
          coupon: null,
        },
      },
    },
  });

  // GA4
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: order.id,
      value: order.totalPriceInclTaxes,
      tax: +order.totalPriceInclTaxes - +order.totalPriceExclTaxes,
      currency: "EUR",
      coupon: null,
      user_email: order.email,
    },
  });
}
