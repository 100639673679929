import React, { Suspense } from "react";
import { Route as ReactRoute, Switch } from "react-router-dom";
import ErrorPage from "../../shared/components/error-page/ErrorPage";
import Loader from "../../shared/components/loader/Loader";

export const Routes = ({ children }) => {
  return (
    <Suspense
      fallback={
        // height styles used to simulate future content and avoid large CLS on footer
        <Loader containerStyles={{ height: "100vh" }} />
      }
    >
      <Switch>
        {children}
        <ReactRoute component={ErrorPage} />
      </Switch>
    </Suspense>
  );
};
