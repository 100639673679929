import React, { Suspense } from "react";
import { Router } from "react-router-dom";
import { BookingProvider } from "./shared/contexts/BookingContext";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import AppRoutes from "./routes";
import Layout from "./layout/Layout";
import Loader from "./shared/components/loader/Loader";
import { initGtm } from "./shared/modules/gtm/service";

export const history = createBrowserHistory();

initGtm();

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <HelmetProvider>
        <Router history={history}>
          <BookingProvider>
            <Layout>
              <AppRoutes />
            </Layout>
          </BookingProvider>
        </Router>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
