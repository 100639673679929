import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { customerServiceEmail } from "../../config";
import { MODAL_COOKIE_HASH } from "../../shared/modules/cookie-consent/CookieConsent";
import { Tooltip } from "react-tippy";

import PlaneIcon from "@bluevalet/react-icons/icon-plane";
import ChevronIcon from "@bluevalet/react-icons/icon-chevron";

import "./Footer.scss";

function WhereToFindUsTooltip({ t }) {
  const sites = t("footer.whereToFindUs.sites", { returnObjects: true });
  const sortedSites = sites.sort((a, b) => a.title.localeCompare(b.title));
  return (
    <div className="FooterTooltipContainer">
      {sortedSites.map((site) => {
        const { places } = site;
        return places.map((place, i) => {
          return (
            <p key={i} className="FooterTooltipLink">
              <PlaneIcon size={16} />
              <Link to={`/${place.link}`}>{place.label}</Link>
            </p>
          );
        });
      })}
    </div>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const year = new Date().getFullYear();

  const handeOpenModal = () => {
    history.push(`${location.pathname}#${MODAL_COOKIE_HASH}`);
  };

  return (
    <div className="Footer">
      <div className="FooterContact">
        <a href={"mailto:" + customerServiceEmail}>{customerServiceEmail}</a>
        <span>{t("footer.customerSuccessSchedule")}</span>
      </div>
      <div className="FooterLinks">
        <Link to="/legal-notices" target="_blank">
          {t("footer.legalNotices")}
        </Link>
        <Link to="/gdpr" target="_blank">
          {t("footer.gdpr")}
        </Link>
        <Link to="/terms" target="_blank">
          {t("footer.terms")}
        </Link>
        <Link to="/comment-ça-marche">{t("footer.howItWorks")}</Link>
        <button onClick={handeOpenModal}>{t("footer.cookies")}</button>
        <Tooltip
          html={<WhereToFindUsTooltip t={t} />}
          position="bottom"
          distance="0"
          className="FooterTooltip"
          useContext={true}
          arrow
          interactive
        >
          <div>
            {t("footer.whereToFindUs.label")}
            <span>
              <ChevronIcon size={18} />
            </span>
          </div>
        </Tooltip>
      </div>
      <div className="FooterCopyright">
        © {year} — Blue Transfer. {t("footer.copyright")}
      </div>
    </div>
  );
}
