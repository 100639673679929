import React, { useEffect } from "react";
import { safeLazy } from "./core/router/utils";
import { Route, useHistory } from "react-router-dom";
import {
  BookingContext,
  BOOKING_CONTEXT_KEY,
  BOOKING_CONTEXT_STORAGE_VERSION,
} from "./shared/contexts/BookingContext";
import { usePersistedContext } from "./shared/contexts/hooks";
import { Routes } from "./core/router/Routes";
import Loader from "./shared/components/loader/Loader";
import { sitesLpData } from "./config";

const HomeComponent = safeLazy(() => import("./components/home/Home"));
const CartRoutes = safeLazy(() => import("./components/cart/routes"));
const ParkingInfoComponent = safeLazy(() =>
  import("./components/how-it-works/parking-infos/ParkingInfos")
);
const HowItWorksComponent = safeLazy(() =>
  import("./components/how-it-works/HowItWorks")
);
const LegalNoticesComponent = safeLazy(() =>
  import("./components/legals/legal-notices/LegalNotices")
);
const GdprComponent = safeLazy(() => import("./components/legals/gdpr/Gdpr"));
const TermsComponent = safeLazy(() =>
  import("./components/legals/terms/Terms")
);

export default function AppRoutes() {
  const [loading, state, setStorageState, handleDeleteState, dispatch] =
    usePersistedContext(
      BookingContext,
      BOOKING_CONTEXT_KEY,
      BOOKING_CONTEXT_STORAGE_VERSION
    );

  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [history]);

  return (
    <Routes>
      <Route path="/comment-ça-marche" component={HowItWorksComponent} exact />
      {sitesLpData.map(({ url, siteName, siteId }) => (
        <Route key={siteId} path={url} exact>
          <ParkingInfoComponent
            setStorageState={setStorageState}
            siteName={siteName}
            siteId={siteId}
          />
        </Route>
      ))}
      <Route path="/legal-notices" component={LegalNoticesComponent} exact />
      <Route path="/gdpr" component={GdprComponent} exact />
      <Route path="/terms" component={TermsComponent} exact />

      <Route path="/">
        {loading ? (
          // height styles used to simulate future content and avoid large CLS on footer
          <Loader containerStyles={{ height: "100vh" }} />
        ) : (
          <Routes>
            <Route path="/" exact>
              <HomeComponent
                state={state}
                dispatch={dispatch}
                setStorageState={setStorageState}
              />
            </Route>
            <CartRoutes
              state={state}
              dispatch={dispatch}
              setStorageState={setStorageState}
              handleDeleteState={handleDeleteState}
            />
          </Routes>
        )}
      </Route>
    </Routes>
  );
}
