import Cookies from "js-cookie";

const COOKIE_CONSENT_COOKIE_NAME = "cookie-consent";
const COOKIE_CONSENT_VERSION = 2;

export function getCookieConsentStateFromCookie() {
  const cookie = Cookies.get(COOKIE_CONSENT_COOKIE_NAME);

  if (cookie) {
    try {
      const state = JSON.parse(cookie);
      if (state.version && state.version >= COOKIE_CONSENT_VERSION) {
        return state;
      }
    } catch (e) {
      console.error("Error during parsing cookie");
    }
  }

  return null;
}

export function setCookieConsentStateInCookie(state) {
  Cookies.set(COOKIE_CONSENT_COOKIE_NAME, JSON.stringify(state), {
    expires: 365,
  });
}

export function generateCookieContentState(consent) {
  const filterAccepted = Object.values(consent).filter((elt) => elt === true);
  const accepted = filterAccepted.length > 0;
  const getState = (state) => (state ? "granted" : "denied");

  return {
    version: COOKIE_CONSENT_VERSION,
    status: accepted ? "accepted" : "declined",
    details: {
      adStorage: getState(consent.adStorage),
      adUserData: getState(consent.adUserData),
      adPersonalization: getState(consent.adPersonalization),
      analyticsStorage: getState(consent.analyticsStorage),
      functionalityStorage: getState(consent.functionalityStorage),
      personalizationStorage: getState(consent.personalizationStorage),
      securityStorage: getState(consent.securityStorage),
    },
  };
}
