import React from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import CookieConsentWrapper from "../shared/modules/cookie-consent/CookieConsentWrapper";

import "./Layout.scss";

export default function Layout({ children }) {
  return (
    <div className="App">
      <Header />
      <div className="LayoutContainer">{children}</div>
      <Footer />
      <CookieConsentWrapper />
    </div>
  );
}
