import localforage from "localforage";

const storage = localforage.createInstance({
  name: "bluetransfer",
  storeName: "storage",
  description: "Blue Transfer storage",
});

function getItem(key) {
  return storage.getItem(key);
}

function setItem(key, value) {
  return storage.setItem(key, value);
}

function removeItem(key) {
  return storage.removeItem(key);
}

const storageService = {
  getItem,
  setItem,
  removeItem,
};

export default storageService;
